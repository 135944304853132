import axios from 'axios'
import apiConfig from '@/config/api'

const api = axios.create({
  baseURL: apiConfig.baseURL,
  timeout: apiConfig.timeout,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// リクエストインターセプター
api.interceptors.request.use(
  (config) => {
    // トークンの追加など、リクエスト送信前の処理をここに記述
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// レスポンスインターセプター
api.interceptors.response.use(
  (response) => {
    // レスポンス受信後の共通処理をここに記述
    return response
  },
  (error) => {
    // エラーハンドリング
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 認証エラー時の処理
          console.log('Unauthorized, logging out ...')
          // ログアウト処理などをここに記述
          break
        case 404:
          console.log('API not found')
          break
        default:
          console.log('An error occurred:', error.response.status)
      }
    } else if (error.request) {
      console.log('No response received:', error.request)
    } else {
      console.log('Error', error.message)
    }
    return Promise.reject(error)
  }
)

export default api

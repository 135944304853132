<template>
  <n-layout-header bordered>
    <n-menu v-model:value="activeKey" :options="menuOptions" />
  </n-layout-header>
</template>

<script setup>
import { h, ref, watch } from 'vue'
import { NIcon } from 'naive-ui'
import { useRouter } from 'vue-router'
import { PersonOutline, SearchOutline, PodiumOutline } from '@vicons/ionicons5'

const router = useRouter()
const activeKey = ref(null)

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

const menuOptions = [
  {
    label: 'アイドルメイクレシピ',
    key: 'top',
    children: [
      {
        label: 'タイムライン',
        key: 'timeline',
        icon: renderIcon(PersonOutline)
      },
      {
        label: '検索',
        key: 'search',
        icon: renderIcon(SearchOutline)
      },
      {
        label: 'ランキング',
        key: 'ranking',
        icon: renderIcon(PodiumOutline)
      }
    ]
  }
]

// Watch for changes in activeKey and navigate accordingly
watch(activeKey, (newKey) => {
  router.push({ name: newKey })
})
</script>

import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import store from './store'
import api from './api'

import {
  NGrid,
  NGridItem,
  NCard,
  NH3,
  NP,
  create,
  NButton,
  NLayout,
  NLayoutHeader,
  NLayoutContent,
  NLayoutFooter,
  NMenu,
  NConfigProvider,
  NMessageProvider,
  NDialogProvider,
  NNotificationProvider,
  NLoadingBarProvider,
  NInput,
  NTabs,
  NTabPane,
  NForm,
  NFormItem,
  NFormItemRow,
  NList,
  NListItem,
  NThing,
  NAvatar,
  NProgress,
  NSpace,
  NText,
  NTag,
  NFlex,
  NPageHeader,
  NTooltip,
  NPopconfirm
} from 'naive-ui'

const naive = create({
  components: [
    NGrid,
    NGridItem,
    NCard,
    NH3,
    NP,
    NButton,
    NLayout,
    NLayoutHeader,
    NLayoutContent,
    NLayoutFooter,
    NMenu,
    NConfigProvider,
    NMessageProvider,
    NDialogProvider,
    NNotificationProvider,
    NLoadingBarProvider,
    NInput,
    NTabs,
    NTabPane,
    NForm,
    NFormItem,
    NFormItemRow,
    NList,
    NListItem,
    NThing,
    NAvatar,
    NProgress,
    NSpace,
    NText,
    NTag,
    NFlex,
    NPageHeader,
    NTooltip,
    NPopconfirm
  ]
})

const pinia = createPinia()
const app = createApp(App)
app.config.globalProperties.$api = api

app.use(pinia).use(naive).use(store).use(router).mount('#app')

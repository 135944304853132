const API_CONFIG = {
  development: {
    baseURL: 'http://localhost:8000/api',
    timeout: 5000
  },
  production: {
    baseURL: 'https://recipe.makeup/api',
    timeout: 10000
  },
  test: {
    baseURL: 'http://test-api.your-idol-cosmetics-site.com/api',
    timeout: 5000
  }
}

const env = process.env.NODE_ENV || 'development'

export default API_CONFIG[env]

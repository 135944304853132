import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'timeline',
    meta: { keepAlive: true },
    component: () => import('../views/TimelinePage.vue')
  },
  {
    path: '/talents/@:account',
    name: 'talents',
    component: () => import('../views/MakeRecipeViewPage.vue')
  },
  {
    path: '/brands/:id',
    name: 'brands',
    component: () => import('../views/BrandPage.vue')
  },
  {
    path: '/products/:id',
    name: 'products',
    component: () => import('../views/ProductPage.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchPage.vue')
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('../views/RankingPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
